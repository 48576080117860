@media only screen and (min-width: 250px) {
  .PortfolioPageContainer {
    width: 100;
    height: 100%;
    .PortfolioPage {
      width: 90%;
      margin: 0 auto;
      min-height: 30vh;
      margin-bottom: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .PortfolioPageContainer {
    .PortfolioPage {
      width: 85%;
      margin-top: 5rem;
      // border-top: 2px solid black;
    }
  }
}
