@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,700;1,300&family=Pacifico&display=swap');

@media only screen and (min-width: 250px) {
  .Resume {
    width: 100%;
    // padding: 2rem;
    .ResumePageContainer {
      padding: 2rem;
      .Nameplate {
        padding: 2rem;
        h1 {
          font-size: 2rem;
          color: rgb(96, 118, 139);
          text-transform: uppercase;
          font-family: 'Open Sans', sans-serif;
        }
      }
      .SocialsResumePDF {
        width: 100%;
      }

      .ResumeContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .experienceContainer {
          padding: 1rem 0;
          width: 100%;
          height: 100%;
          margin: 2rem 0;
          .experienceContainerHeader {
            width: 100%;
            h1 {
              font-size: 1.9rem;
              text-align: left;
              font-family: 'Roboto', sans-serif;
            }
          }
        }

        .skillsContainer {
          border-top: 1px solid black;
          padding: 4rem;
          width: 100%;
          padding: 1rem;
          height: 100%;
          .skillsContainerHeader {
            h1 {
              font-size: 1.9rem;
              text-align: left;
              font-family: 'Roboto', sans-serif;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 650px) {
  .Resume {
    .ResumePageContainer {
      .Nameplate {
        padding: 2rem;
        h1 {
          font-size: 3rem;
        }
      }
      .SocialsResumePDF {
        width: 90%;
        margin: 0 auto;
      }
    }
  }
}

@media only screen and (min-width: 1040px) {
  .Resume {
    .ResumePageContainer {
      .SocialsResumePDF {
        width: 70%;
      }
    }
  }
}

@media only screen and (min-width: 1240px) {
  .Resume {
    .ResumePageContainer {
      width: 90%;
      margin: 0 auto;

      .Nameplate {
        h1 {
          font-size: 3.7rem;
        }
      }

      .SocialsResumePDF {
        width: 70%;
      }
      .ResumeContainer {
        width: 100%;

        flex-direction: row-reverse;

        .skillsContainer {
          border: none;
          width: 50%;
          margin: 0 auto;
          margin-top: 3rem;
        }

        .experienceContainer {
          padding: 1rem;
          width: 100%;
          height: 100%;
          margin-top: -10rem;
          .experienceContainerHeader {
            width: 100%;
            h1 {
              font-size: 1.9rem;
              text-align: left;
              font-family: 'Roboto', sans-serif;
            }
          }
        }
      }
    }
  }
}
