.skills {
  margin: 1rem;
  margin-top: 2rem;
  width: 100%;
  .skillsHeader {
    padding: 0.5rem;
    h2 {
      font-size: 1.3rem;
      text-align: left;
      font-family: 'Roboto', sans-serif;
    }
  }
}
