@import url('https://fonts.googleapis.com/css2?family=Mansalva&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Original+Surfer&display=swap');

@media only screen and (min-width: 320px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    body {
      margin: 0;
      // background-color: rgba(221, 197, 182, 0.3);
      background-color: white;
    }
  }
}
