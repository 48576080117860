@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,100;1,300&family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=BhuTuka+Expanded+One&family=Comfortaa:wght@300;687&family=Rubik+Glitch&display=swap');

@media only screen and (min-width: 240px) {
  .AboutPage {
    margin-top: 1rem;
    width: 100%;
    .AboutPageContainer {
      width: 100%;
      height: 100%;
      margin: 4rem auto;
      .AboutPageImage {
        width: 65%;
        height: 42vh;
        margin: 0 auto;
        margin-top: 4rem;

        .ImageContainer {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3),
              0.3em 0.3em 1em rgb(96, 118, 139, 0.3);
          }
        }
      }
      .aboutheaderContainer {
        width: 100%;
        padding: 2rem;
        .aboutheader {
          width: 100%;
          padding: 1rem;
          h1 {
            font-family: 'Comfortaa', cursive;
            color: rgb(96, 118, 139);
            letter-spacing: 5px;
            font-size: 3rem;
          }
        }
        .aboutheaderParagraph {
          width: 100%;
          padding: 1rem;
          font-size: 0.8rem;
          p {
            color: black;
            font-family: 'Open Sans', sans-serif;
            line-height: 1.7rem;
            font-style: italic;
            letter-spacing: 1px;
          }
        }
      }
      .aboutStoryCardContainer {
        padding: 0.5rem;
        // border: 2px solid black;
        width: 75%;
        margin: 1rem auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        column-gap: 4rem;
        row-gap: 3rem;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
      }

      .aboutRecommendationsContainer {
        margin-top: 5rem;
        width: 100%;
        padding: 2rem;
        .header {
          padding: 1rem;
          padding-bottom: 2rem;
          h2 {
            font-family: 'Comfortaa', cursive;
            color: rgb(96, 118, 139);
            letter-spacing: 5px;
            font-size: 1.5rem;
            text-align: left;
          }
        }
        .aboutRecommendationslistContainer {
          padding: 2rem;
          border-top: 2px solid black;
          width: 100%;
          // border: 2px solid black;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: auto;
          column-gap: 4rem;
          // row-gap: 3rem;
          justify-content: center;
          align-items: center;
          .aboutRecommendationslist {
            width: 100%;
            h3 {
              text-align: left;
              font-family: 'BhuTuka Expanded One', cursive;
              letter-spacing: 5px;
            }
            ul {
              padding: 1rem 2rem;
              // border: 2px solid black;
              li {
                // list-style-type: none;
                text-align: left;
                font-weight: 100;
                padding: 0.5rem;
                a {
                  color: red;
                  text-decoration: none;
                  font-size: 1rem;
                  font-family: 'Open Sans', sans-serif;
                  letter-spacing: 3px;
                  line-height: 1.2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 640px) {
  .AboutPage {
    .AboutPageContainer {
      .AboutPageImage {
        width: 45%;
        height: 48vh;
      }

      .aboutheaderContainer {
        .aboutheader {
          font-size: 1.5rem;
        }
        .aboutheaderParagraph {
          p {
            font-size: 1rem;
          }
        }
      }

      .aboutStoryCardContainer {
        width: 80%;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .AboutPage {
    .AboutPageContainer {
      width: 95%;
      .AboutPageImage {
        width: 40%;
        height: 35vh;
      }

      .aboutheaderContainer {
        .aboutheader {
          h1 {
            font-size: 4rem;
          }
        }
      }
      .aboutStoryCardContainer {
        width: 80%;
        grid-template-columns: repeat(2, 1fr);
      }

      .aboutRecommendationsContainer {
        margin-top: 5rem;
        width: 100%;
        padding: 2rem;
        .header {
          padding: 1rem;
          h2 {
            font-family: 'Comfortaa', cursive;
            color: rgb(96, 118, 139);
            letter-spacing: 5px;
            font-size: 1.5rem;
            text-align: left;
            font-size: 3rem;
          }
        }

        .aboutRecommendationslistContainer {
          grid-template-columns: repeat(3, 1fr);
          column-gap: 4rem;

          .aboutRecommendationslist {
            width: 100%;

            h3 {
              text-align: left;
              font-family: 'BhuTuka Expanded One', cursive;
              letter-spacing: 5px;
              font-size: 1.5rem;
            }
            ul {
              padding: 1rem 2rem;
              min-height: 10em;
              // border: 2px solid black;
              li {
                // list-style-type: none;
                text-align: left;
                font-weight: 100;
                padding: 0.5rem;

                a {
                  color: red;
                  text-decoration: none;
                  font-size: 1rem;
                  font-family: 'Open Sans', sans-serif;
                  letter-spacing: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1366px) {
  .AboutPage {
    .AboutPageContainer {
      width: 90%;
      .AboutPageImage {
        width: 25%;
        height: 38vh;
      }

      // .aboutheaderContainer {
      //   .aboutheader {
      //     h1 {
      //       font-size: 5rem;
      //     }
      //   }
      // }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .AboutPage {
    .AboutPageContainer {
      width: 75%;

      .aboutheaderContainer {
        .aboutheader {
          font-size: 2rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 2500px) {
  .AboutPage {
    .AboutPageContainer {
      width: 50%;
    }
  }
}
