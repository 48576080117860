@import url('https://fonts.googleapis.com/css2?family=BhuTuka+Expanded+One&family=Comfortaa:wght@300;687&family=Rubik+Glitch&display=swap');

@media only screen and (min-width: 240px) {
  .MobileFooter {
    display: block;
    width: 100%;
    height: 100%;
    background-color: black;
    // position: sticky;
    // bottom: 0;
    // z-index: 1;
    padding: 2rem;
    .footerDiv {
      width: 80%;
      margin: 0 auto;
      padding: 2rem 0.5rem;
    }
    .MobileFooterContainer {
      padding-top: 3rem;
      padding-bottom: 2rem;
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      li {
        list-style: none;
        div {
          margin-top: 0.5rem;
          p {
            color: white;
            font-size: 0.9rem;
            font-family: 'Comfortaa', cursive;
          }
        }
        .icon {
          a {
            text-decoration: none;
            color: white;
            font-size: 1.5rem;
          }
          a:hover {
            color: rgb(96, 118, 139);
          }
        }
        .link {
          margin-top: 0.5rem;
          a {
            text-decoration: none;
            font-family: 'Comfortaa', cursive;
            color: white;
          }
          a:hover {
            color: rgb(96, 118, 139);
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  .MobileFooter {
    width: 100%;
    .footerDiv {
      width: 70%;
      margin: 0 auto;
      margin-top: 1rem;
    }

    .MobileFooterContainer {
      width: 65%;
    }
  }
}

// @media only screen and (min-width: 1024px) {
//   .MobileFooter {
//     display: none;
//   }
// }
