.SkillsCard {
  width: 100%;
  padding: 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .skillsIcon {
    font-size: 1.2rem;
    color: rgb(96, 118, 139);
    margin-left: 1rem;
  }
  .skillName {
    width: 100%;
    margin-left: 1.5rem;
    h2 {
      font-size: 1rem;
      font-family: 'Ubuntu', sans-serif;
      text-align: left;
      font-weight: 300;
    }
  }
}
