@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=BhuTuka+Expanded+One&family=Rubik+Glitch&display=swap');

@media only screen and (min-width: 240px) {
  .MobileNavbar {
    display: block;
    width: 100%;
    height: 5.5rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    .MobileNavbarContainer {
      margin-top: 1rem;
      padding: 2rem;
      width: 100;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .MobileNavbarLogo {
        a {
          text-decoration: none;
          h1 {
            color: black;
            text-transform: uppercase;
            // font-family: 'Pacifico', cursive;
            font-family: 'Rubik Glitch', cursive;
            letter-spacing: 1rem;
            font-size: 3rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .MobileNavbar {
    display: none;
  }
}
