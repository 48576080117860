@import url('https://fonts.googleapis.com/css2?family=BhuTuka+Expanded+One&family=Comfortaa:wght@300;687&family=Rubik+Glitch&display=swap');

.Contact {
  width: 100%;
  height: 100%;
  .ContactContainer {
    width: 80%;
    // min-height: 57rem;
    height: 100%;
    margin: 6rem auto;
    // margin-bottom: 14rem;
    .contactHeaderContainer {
      margin: 4rem auto;
      padding: 1rem;
      .contactHeader {
        h1 {
          font-size: 4.5rem;
          padding: 1rem;
          text-transform: capitalize;
          font-family: 'Comfortaa', cursive;
        }
      }
      .contactHeaderQoute {
        padding: 1rem;
        p {
          font-family: 'Ubuntu', sans-serif;
          font-weight: 300;
          span {
            padding: 1rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1329px) {
  .Contact {
    .ContactContainer {
      width: 95%;
    }
  }
}

@media only screen and (max-width: 777px) {
  .Contact {
    .ContactContainer {
      .contactHeaderContainer {
        .contactHeader {
          h1 {
            font-size: 3.5rem;
          }
        }
        .contactHeaderQoute {
          p {
            font-size: 0.8rem;
            span {
              padding: 1rem;
            }
          }
        }
      }
    }
  }
}
