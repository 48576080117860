@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,700;1,300&family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');

@import url('https://fonts.googleapis.com/css2?family=BhuTuka+Expanded+One&family=Comfortaa:wght@300;687&family=Rubik+Glitch&display=swap');

@media only screen and (min-width: 240px) {
  .LandingPage {
    padding: 1rem;
    width: 100%;

    .LandingPageContainer {
      padding: 1rem 0;
      width: 100%;
      margin: 0 auto;

      .LandingInfoContainer {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;

        .ImageSection {
          display: none;
          padding-top: 2rem;
          width: 40%;
          margin: 0 auto;
          height: 100%;

          .ImageContainer {
            margin: 0 auto;
            width: 80%;
            height: 100%;
            background: rgba(27, 27, 27, 0.2);
            box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3),
              0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
            img {
              width: 100%;
              height: 100%;
              box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3),
                0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
            }
          }
        }

        .verticalLine {
          display: none;
          border-left: 2px solid black;
          padding: 1rem;
          height: 500px;
          margin: 1rem;
        }

        .infoContainer {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .mobile {
            display: block;
            padding-top: 3rem;
            width: 80%;
            margin: 0 auto;
            height: 100%;

            .ImageContainer {
              margin: 0 auto;
              width: 80%;
              height: 100%;
              background: rgba(27, 27, 27, 0.2);
              box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3),
                0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
              img {
                width: 100%;
                height: 100%;
                box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3),
                  0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
              }
            }
          }

          .NamePlateContainer {
            padding-top: 3rem;
            width: 80%;
            margin: 0 auto;
            h1 {
              // font-family: 'Open Sans', sans-serif;
              // font-family: 'League Gothic', sans-serif;
              font-family: 'Comfortaa', cursive;
              letter-spacing: 8px;
              // font-weight: 100;
              font-size: 2rem;
              color: rgb(30, 30, 29);
              // text-align: left;
              text-align: left;
              line-height: 2.5rem;
            }
          }

          .BioHeader {
            padding: 1rem;
            padding-top: 1.8rem;

            h2 {
              font-family: 'Pacifico', cursive;
              font-size: 4rem;
              text-transform: capitalize;
              // color: rgb(30, 30, 29);
              color: rgb(96, 118, 139);
            }
          }

          .BioContainer {
            padding-top: 1.8rem;
            // border: 1px solid black;
            width: 90%;

            .certication {
              padding: 1rem;
              padding-top: 1.8rem;
              h2 {
                text-transform: uppercase;
                // font-family: 'Comfortaa', cursive;
                font-family: 'BhuTuka Expanded One', cursive;
                line-height: 1.5rem;
                font-size: 1rem;
                letter-spacing: 5px;
                span {
                  text-transform: lowercase;
                  font-family: 'BhuTuka Expanded One', cursive;
                  // line-height: 1.5rem;
                  // font-size: 1rem;
                  // letter-spacing: 5px;
                }
              }
            }

            p {
              text-align: justify;
              font-size: 0.8rem;
              color: black;
              font-family: 'Roboto', sans-serif;
              line-height: 1.5rem;
              letter-spacing: 1px;
              font-weight: bolder;
            }
          }
        }
      }

      .SocialsResumePDF {
        width: 100%;
        border: 1px solid black;
        // margin-top: 2rem;
        padding: 0 0.8rem;
      }
    }
  }
}

// @media only screen and (min-width: 650px) {
//   .LandingPage {
//     .LandingPageContainer {
//       .SocialsResumePDF {
//         width: 85%;
//         margin: 2rem auto;
//         margin-top: 4rem;
//         padding-top: 3rem;
//       }
//     }
//   }
// }

@media only screen and (min-width: 724px) {
  .LandingPage {
    .LandingPageContainer {
      .LandingInfoContainer {
        .infoContainer {
          .mobile {
            width: 50%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .LandingPage {
    .LandingPageContainer {
      .SocialsResumePDF {
        width: 65%;
      }
      .LandingInfoContainer {
        flex-direction: row-reverse;
        .ImageSection {
          display: block;
          width: 60%;
        }

        .infoContainer {
          .mobile {
            display: none;
          }
          .NamePlateContainer {
            width: 90%;
            margin: 0 auto;
            h1 {
              font-size: 1.3rem;
            }
          }

          .BioHeader {
            padding: 1rem 0.3rem;

            h2 {
              font-size: 3rem;
            }
          }

          .verticalLine {
            display: block;
          }

          .BioContainer {
            padding-top: 1.5rem;
            width: 90%;
            p {
              // font-size: 1rem;
              color: black;
              font-family: 'Open Sans', sans-serif;
              line-height: 1.7rem;
              font-style: italic;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1265px) {
  .LandingPage {
    .LandingPageContainer {
      .LandingInfoContainer {
        width: 85%;
        margin: 0 auto;

        .ImageSection {
          width: 45%;
          .ImageContainer {
            width: 100%;
          }
        }

        // .NamePlateContainer {
        //   width: 100%;
        //   margin: 0;
        //   h1 {
        //     font-size: 1.3rem;
        //   }
        // }

        // .verticalLine {
        //   display: block;
        // }

        .infoContainer {
          .NamePlateContainer {
            h1 {
              font-size: 2.7rem;
              letter-spacing: 6px;
              line-height: 3.5rem;
            }
          }

          .BioHeader {
            h2 {
              font-size: 3.8rem;
            }
          }

          .BioContainer {
            padding: 2rem;
            width: 90%;
            .certication {
              padding: 1rem;
              padding-top: 1.8rem;
              h2 {
                text-transform: uppercase;
                font-family: 'Comfortaa', cursive;
                font-size: 1rem;
                span {
                  font-family: 'Comfortaa', cursive;
                }
              }
            }
            p {
              font-size: 1rem;
              line-height: 1.7rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1824px) {
  .LandingPage {
    .LandingPageContainer {
      .LandingInfoContainer {
        width: 65%;

        .ImageSection {
          width: 40%;
        }

        .infoContainer {
          width: 80%;
          margin: 0 auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 2500px) {
  .LandingPage {
    .LandingPageContainer {
      .LandingInfoContainer {
        width: 50%;

        .ImageSection {
          width: 35%;
        }
      }
    }
  }
}
