@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@1,300&display=swap');

@media only screen and (min-width: 240px) {
  .StoryCard {
    padding: 1.5rem;
    margin: 0 auto;
    width: 100%;
    border-radius: 5rem;
    min-height: 31em;
    background-color: white;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3),
      0.3em 0.3em 1em rgba(0, 0, 0, 0.3);

    .storyCardStageContainer {
      padding: 0.2rem;
      .storyCardStage {
        padding: 1rem;
        background-color: rgb(96, 118, 139);
        border: 2px solid rgb(57, 70, 81);
        box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3),
          0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
        border-radius: 100%;
        width: 10%;
        margin: 0 auto;
        p {
          font-size: 0.5rem;
          color: white;
        }
      }
    }
    .storyCardIconConatiner {
      padding: 0.2rem;
      margin: 2rem auto;
      i {
        font-size: 3rem;
        color: rgb(96, 118, 139);
      }
    }
    .storyCardParagraphConatiner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-family: 'Ubuntu', sans-serif;
        font-size: 0.6rem;
        font-weight: 300;
        line-height: 1.3rem;
        letter-spacing: 1px;
      }
    }
  }
}

@media only screen and (min-width: 640px) {
  .StoryCard {
    padding: 2rem;

    .storyCardIconConatiner {
      i {
        font-size: 3.5rem;
      }
    }
    .storyCardParagraphConatiner {
      p {
        line-height: 1.5rem;
        font-size: 0.6rem;
        letter-spacing: 2px;
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .StoryCard {
    padding: 2rem;
    height: 34em;

    .storyCardIconConatiner {
      i {
        font-size: 3.5rem;
      }
    }
    .storyCardParagraphConatiner {
      p {
        line-height: 1.5rem;
        font-size: 0.7rem;
        letter-spacing: 2px;
      }
    }
  }
}
