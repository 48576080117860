@import url('https://fonts.googleapis.com/css2?family=BhuTuka+Expanded+One&family=Comfortaa:wght@300;687&family=Rubik+Glitch&display=swap');

@media only screen and (min-width: 240px) {
  .Contactme {
    width: 100%;
    min-height: 40vh;
    padding: 1rem;
    .ContactmeContainer {
      width: 100%;
      height: 100%;
      padding: 1rem;

      form {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        .formName {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          column-gap: 2rem;
        }
        .formButton {
          width: 50%;
          margin: 0 auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .Contactme {
    .ContactmeContainer {
      width: 80%;
      margin: 0 auto;
      padding: 1rem;
      form {
        .formButton {
          padding: 1rem;
          width: 30%;
        }
      }
    }
  }
}
