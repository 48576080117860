@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,700;1,300&family=Pacifico&display=swap');

@media only screen and (min-width: 250px) {
  .Portfolio {
    width: 100%;
    height: 100%;
    padding: 4rem 1.5rem;
    background-color: rgba(126, 146, 163, 0.5);
    .PortfolioConatiner {
      width: 100%;
      height: 100%;
      padding-bottom: 2rem;
      .PortfolioHeaderConatiner {
        width: 100%;
        margin: 0 auto;
        height: 100%;
        display: flex;
        flex-direction: row;

        justify-content: space-between;
        align-items: center;
        .PortfolioHeaderConatinerborder {
          width: 50%;
        }
        .Header {
          margin-top: 3rem;
          padding: 0.5rem;
          width: 100%;
          h1 {
            text-transform: uppercase;
            color: black;
            font-family: 'Montserrat', sans-serif;
            letter-spacing: 2px;
            font-size: 1.2rem;
          }
        }
      }
      .PortfolioCarrouselContainer {
        border: 2px solid black;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media only screen and (min-width: 650px) {
  .Portfolio {
    .PortfolioConatiner {
      .PortfolioHeaderConatiner {
        width: 80%;
        .PortfolioHeaderConatinerborder {
          width: 81%;
        }
        .Header {
          h1 {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .Portfolio {
    .PortfolioConatiner {
      .PortfolioHeaderConatiner {
        width: 80%;
        .PortfolioHeaderConatinerborder {
          width: 100%;
        }
        .Header {
          padding: 0.5rem;
          width: 90%;
          h1 {
            font-size: 1.2rem;
            letter-spacing: 3px;
          }
        }
      }
    }
  }
}
