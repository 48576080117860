@import url('https://fonts.googleapis.com/css2?family=BhuTuka+Expanded+One&family=Comfortaa:wght@300;687&family=Rubik+Glitch&display=swap');

@media only screen and (min-width: 250px) {
  .PortfolioPages {
    width: 100%;
    height: 100%;
    margin-top: 1.6rem;
    .PortfolioPagesContainer {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      column-gap: 2rem;
      justify-content: space-between;
      align-items: center;

      .ProjectBox {
        width: 80%;
        height: 6rem;
        padding: 4px;
        background-color: white;
        border-radius: 0.5rem;
        box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3),
          0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        .ProjectName {
          width: 100%;
          padding: 10px;
          // border: 2px solid black;
          a {
            text-decoration: none;
            color: black;
            font-family: 'Comfortaa', cursive;
            letter-spacing: 0.4rem;
            font-size: 0.8rem;
            font-weight: 400;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 650px) {
  .PortfolioPages {
    margin-top: 3rem;
    margin-bottom: 2rem;
    .PortfolioPagesContainer {
      width: 80%;
      .ProjectBox {
        width: 90%;
        height: 8rem;
        .ProjectName {
          a {
            // letter-spacing: 1px;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .PortfolioPages {
    margin-top: 3rem;
    margin-bottom: 2rem;
    .PortfolioPagesContainer {
      width: 65%;
      column-gap: 4rem;
      .ProjectBox {
        height: 10rem;
        border-radius: 1.5rem;
        .ProjectName {
          padding: 10px;
          a {
            letter-spacing: 0.5rem;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1237px) {
  .PortfolioPages {
    .PortfolioPagesContainer {
      width: 65%;
      border: none;
    }
  }
}

@media only screen and (min-width: 2500px) {
  .PortfolioPages {
    .PortfolioPagesContainer {
      width: 47%;
    }
  }
}
