.EducationCard {
  margin: 2rem 1rem;

  .ExperienceCardHeader {
    h2 {
      font-weight: 900;
      font-size: 1.5rem;
      text-align: left;
      font-family: 'Roboto', sans-serif;
    }
  }

  .ExperienceCardPositionTimeline {
    padding: 0.5rem 0;
    h3 {
      font-size: 1.1rem;
      text-align: left;
      color: rgb(96, 118, 139);
    }
    span {
      margin: 0.5rem;
      font-size: 1rem;
      font-weight: 600;
      color: black;
      font-family: 'Roboto', sans-serif;
    }
  }
}

@media only screen and (max-width: 832px) {
  .EducationCard {
    margin: 2rem 0.5rem;

    .ExperienceCardHeader {
      h2 {
        font-size: 1.2rem;
      }
    }

    .ExperienceCardPositionTimeline {
      padding: 0.5rem 0;
      h3 {
        font-size: 1rem;
      }
      span {
        font-size: 0.9rem;
      }
    }
  }
}
