.ExperienceCard {
  margin: 2rem 1rem;

  .ExperienceCardHeader {
    h2 {
      font-weight: 900;
      font-size: 1.5rem;
      text-align: left;
      font-family: 'Roboto', sans-serif;
    }
  }

  .ExperienceCardPositionTimeline {
    padding: 0.5rem 0;
    h3 {
      font-size: 1.1rem;
      text-align: left;
      color: rgb(96, 118, 139);
    }
    span {
      font-size: 1rem;
      font-weight: 600;
      color: black;
      font-family: 'Roboto', sans-serif;
    }
  }
  .ExperienceCardDescription {
    ul {
      padding: 0.5rem;
      li {
        font-size: 0.9rem;
        font-family: 'Ubuntu', sans-serif;
        text-align: left;
        font-weight: 300;
        line-height: 1.7rem;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .ExperienceCard {
    margin: 2rem 0.5rem;
  }
}
