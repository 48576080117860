@import url('https://fonts.googleapis.com/css2?family=Mansalva&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=BhuTuka+Expanded+One&family=Rubik+Glitch&display=swap');

@media only screen and (min-width: 240px) {
  .Navbar {
    display: none;
    width: 100%;
    padding-top: 2rem;
    height: 100%;

    .overlayContainer {
      padding: 1.7rem;
      width: 100%;

      .NavbarContainer {
        margin: 0 auto;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .NavLogo {
          width: 70%;
          margin: 0 3rem;
          text-align: left;

          a {
            text-decoration: none;
            color: black;
            h1 {
              text-align: left;
              font-family: 'Pacifico', cursive;
              text-transform: uppercase;
              font-family: 'Rubik Glitch', cursive;
              font-size: 3rem;
              letter-spacing: 2rem;
            }
          }
        }
        .NavLinks {
          width: 100%;
          margin: 0 2rem;
          ul {
            margin: 0 2rem;
            width: 100%;
            list-style: none;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            li {
              padding: 0.2rem;
              a {
                text-decoration: none;
                color: black;
                // font-family: 'Montserrat', sans-serif;
                font-family: 'BhuTuka Expanded One', cursive;
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .Navbar {
    display: block;
    .overlayContainer {
      .NavbarContainer {
        width: 90%;

        .NavLogo {
          width: 40%;
        }
      }
    }
  }
}

@media only screen and (min-width: 2500px) {
  .Navbar {
    .overlayContainer {
      .NavbarContainer {
        width: 70%;
      }
    }
  }
}
