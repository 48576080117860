@import url('https://fonts.googleapis.com/css2?family=Mansalva&family=Roboto:wght@300&display=swap');

@media only screen and (min-width: 250px) {
  .footerDivContainer {
    width: 100%;
    min-height: 25vh;
    background-color: rgb(96, 118, 139);
    position: relative;
  }
}

@media only screen and (min-width: 750px) {
  .footerDivContainer {
    .footerDiv {
      width: 70%;
      margin: 0 auto;
      margin-top: 1rem;
    }
  }
}

@media only screen and (min-width: 1050px) {
  .footerDivContainer {
    .footerDiv {
      width: 40%;
    }
  }
}
