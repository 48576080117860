@media only screen and (min-width: 250px) {
  .socialMedia {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    row-gap: 0;
    li {
      list-style-type: none;
      a {
        color: white;
        font-size: 0.7rem;
      }
    }
  }
}

@media only screen and (min-width: 650px) {
  .socialMedia {
    li {
      a {
        font-size: 0.9rem;
      }
    }
  }
}
