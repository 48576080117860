@import url('https://fonts.googleapis.com/css2?family=BhuTuka+Expanded+One&family=Comfortaa:wght@300;687&family=Rubik+Glitch&display=swap');

@media only screen and (min-width: 250px) {
  .CategoriesContainer {
    width: 100;
    height: 100%;
    .CategoriesPage {
      width: 100%;
      margin-top: 4rem;
      margin-bottom: 6rem;
      min-height: 50vh;
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      justify-content: space-between;
      align-items: center;

      .ProjectContainer {
        width: 80%;
        min-height: 10em;
        margin: 0 auto;
        padding: 1.5rem;
        background-color: white;
        border-radius: 0.5rem;
        box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3),
          0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
        .Project {
          padding: 0.5rem;
          border: 2px solid black;
          .ApplicationCard {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .ViewMore {
              width: 100%;
              padding: 1rem;
              button {
                background-color: rgb(96, 118, 139);
                box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3),
                  0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
                border: none;
                color: white;
                padding: 1rem 2rem;
                border-radius: 7%;
              }
            }
            .ApplicationImage {
              height: 12rem;
              padding: 1rem;
              // width: 20rem;
              width: 15rem;
              img {
                width: 100%;
                height: 100%;

                box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0.3em 0.3em 1em rgba(27, 27, 27, 0.5),
                  0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
              }
            }
            .ApplicationName {
              margin-top: 3rem;
              padding: 1rem;
              h2 {
                font-size: 2rem;
                font-family: 'Comfortaa', cursive;
                font-weight: 800;
              }
            }
            .ApplicationLinksContainer {
              padding: 1rem;
              padding-bottom: 0.5rem;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              .ApplicationLinks {
                padding: 0.3rem 1.5rem;
                a {
                  text-decoration: none;
                  i {
                    color: rgb(96, 118, 139);
                    font-size: 2rem;
                  }
                }
              }
            }

            .ApplicationDescription {
              padding: 1rem;
              padding-bottom: 0;
              p {
                text-align: justify;
                font-size: 0.8rem;
                color: black;
                font-family: 'Roboto', sans-serif;
                line-height: 1.5rem;
                letter-spacing: 2px;
              }
            }

            .Domain {
              padding: 0.5rem;
              h3 {
                font-family: 'Comfortaa', cursive;
              }
            }

            .ApplicationSkillsnTech {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-bottom: 0.5rem;
              .SkillsnTech {
                padding: 0.5rem;
                margin-top: 0.5rem;
                width: 100%;
                height: 100%;
                h4 {
                  font-family: 'Comfortaa', cursive;
                  text-align: left;
                  margin: 0.5rem;
                }
                p {
                  display: list-item;
                  list-style-type: square;
                  margin-left: 1em;
                  // list-style-position: inside;
                  text-align: left;
                  font-size: 0.7rem;
                  color: black;
                  font-family: 'Roboto', sans-serif;
                  line-height: 1.5rem;
                  letter-spacing: 2px;
                  // font-weight: bolder;
                }
              }
            }

            .projectDescription {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              .projectDescriptionHeader {
                width: 60%;
                margin: 0 auto;
                h2 {
                  font-family: 'Roboto', sans-serif;
                  font-size: 1.4rem;
                  color: rgb(96, 118, 139);
                }
              }
              .projectDescriptionContent {
                width: 80%;
                margin: 0 auto;
                height: 100%;
                padding: 1rem;

                p {
                  text-align: justify;
                  font-size: 0.9rem;
                  color: black;
                  font-family: 'Roboto', sans-serif;
                  line-height: 1.5rem;
                  letter-spacing: 2px;
                  font-weight: bolder;
                }

                a {
                  width: 100%;
                  text-decoration: none;
                  padding: 1rem;
                  i {
                    color: rgb(96, 118, 139);
                    font-size: 3.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 650px) {
  .CategoriesContainer {
    .CategoriesPage {
      .ProjectContainer {
        width: 70%;
        .Project {
          .ApplicationCard {
            .projectDescription {
              .projectDescriptionHeader {
                h2 {
                  font-size: 1.4rem;
                  color: rgb(96, 118, 139);
                }
              }

              .projectDescriptionContent {
                width: 100%;
                p {
                  text-align: justify;
                }
              }
            }

            .ApplicationSkillsnTech {
              padding: 1rem;
              .SkillsnTech {
                min-height: 15vh;
                p {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1250px) {
  .CategoriesContainer {
    .CategoriesPage {
      padding: 4rem;
      display: grid;
      grid-template-columns: repeat(2, auto);
      justify-content: center;
      align-items: center;
      column-gap: 2rem;
      row-gap: 4rem;
      width: 90%;
      margin: 4rem auto;
      border-top: 2px solid black;
      .ProjectContainer {
        width: 100%;
        height: 100%;
        .Project {
          width: 100%;
          height: 100%;
          .ApplicationCard {
            .ApplicationImage {
              width: 20rem;
            }
            .projectDescription {
              .projectDescriptionHeader {
                width: 60%;
                h2 {
                  font-size: 1.4rem;
                  color: rgb(96, 118, 139);
                }
              }
            }

            .ApplicationSkillsnTech {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 0.3rem;
              width: 100%;
              margin: 0;
              padding: 0;
              margin-top: 1rem;

              .SkillsnTech {
                width: 100%;
                height: 100%;
                padding: 1.2rem;
                h4 {
                  padding: 0.5rem;
                  font-size: 1.1rem;
                }

                p {
                  width: 100%;
                  font-size: 0.7rem;
                }
              }

              // .techBox {
              //   border-right: 1px solid black;
              // }

              // .skillBox {
              //   border-left: 1px solid black;
              // }

              .skill {
                font-size: 0.6rem;
              }
            }
          }
        }
      }
    }
  }
}

// @media only screen and (min-width: 1124px) {
//   .CategoriesContainer {
//     .CategoriesPage {
//       padding: 4rem;
//       column-gap: 2rem;
//       row-gap: 6rem;
//       width: 90%;
//       margin: 4rem auto;
//       .ProjectContainer {
//         width: 90%;
//       }
//     }
//   }
// }
